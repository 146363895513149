<script setup>
// import Components
import MultiSelect from '../../components/MultiSelect.vue'
import { useNotification } from "@kyvg/vue3-notification"

import { ref, computed, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex';

const route = useRoute()
const store = useStore()
const { notify } = useNotification()

// state
const productServices = ref([])
const excludedServiceValues = ref([])
const selectedExclude = ref(null) // first selector
const selectedExcludeFrom = ref(null) // second selector
const excludeFromSelectorItems = ref([])
const isLoading = ref(true)
const firstSelectorIsLoading = ref(true)

// computed
const appLocale = computed(() => store.state.locale.appLocale)
const productServiceValues = computed(() => {
	const array = []
	productServices.value.forEach(el => {
		array.push(...el.values)
	})
	return array
})

// watch
watchEffect(() => {
	if (selectedExclude.value) {
		const clonedProductServices = structuredClone(productServices.value)
		const excludeFrom_listItems = []
		let matchedExcludeFromItem, matchedFromExcludeItem
		for (let service of clonedProductServices) {
			if (service.service_id === selectedExclude.value.service_id) { // skip service values of the first selector 
				continue
			}
			for (let i = service.values.length - 1; i >= 0; i--) {
				// skip the item already in the excluded list
				matchedExcludeFromItem = excludedServiceValues.value.find(item => item.exclude === selectedExclude.value.service_value_id && item.from === service.values[i].service_value_id)
				matchedFromExcludeItem = excludedServiceValues.value.find(item => item.from === selectedExclude.value.service_value_id && item.exclude === service.values[i].service_value_id)
				if (matchedExcludeFromItem || matchedFromExcludeItem) {
					service.values.splice(i, 1)
				}
			}
			excludeFrom_listItems.push(service)
		}
		excludeFromSelectorItems.value = excludeFrom_listItems
	}
})

// methods
async function getProductServiceValues() {
	const urlParams = { product_id: route.params.id }
	store.dispatch('product/services/getServices', urlParams).then(async (res) => { // get product services
		const promises = res.map(async el => getServiceValue(el.pivot.service_id)) // get each service's values
		productServices.value = await Promise.all(promises)
	})
		.catch((error) => {
			notify({
				text: error,
				group: 'failed'
			});
		}).finally(() => {
			firstSelectorIsLoading.value = false
		})
}

function getServiceValue(id) {
	const urlParams = { service_id: id, language: appLocale.value }
	return store.dispatch('service/fetch', urlParams)
}

function getExcludedServiceValues() {
	store.dispatch('product/services/getExcludedServiceValues', { product_id: route.params.id }).then(res => {
		excludedServiceValues.value = res
	}).catch(error => {
		notify({
			text: error,
			group: 'failed'
		})
	}).finally(() => {
		isLoading.value = false
	})
}

function setExcludedServiceValues() {
	const urlParams = { product_id: route.params.id }
	const excludedValues = {
		serviceValues: excludedServiceValues.value
	}
	store.dispatch('product/services/setExcludedServiceValues', { urlParams, excludedValues }).then(res => {
		notify({
			text: res,
			group: 'success'
		})
	}).catch(error => {
		notify({
			text: error,
			group: 'failed'
		})
	})
}

function addToExcludedList() {
	if (selectedExclude.value && selectedExcludeFrom.value) {
		excludedServiceValues.value.push({ exclude: selectedExclude.value.service_value_id, from: selectedExcludeFrom.value.service_value_id })
		selectedExcludeFrom.value = null
	}

}

getProductServiceValues()
getExcludedServiceValues()

defineExpose({
	save: setExcludedServiceValues
})
</script>

<template>
	<div class="page-body-main-create">
		<div class="card-wrapper">
			<slot name="nav-stepper"></slot>
			<div class="card-title">{{ $t('product.excludeServiceValues') }}</div>
			<div class="card">
				<div class="card-header d-flex">
					<div class="flex-grow-1">
						<label class="form-label fw-bold">{{ $t('product.addServices.excludeValuesLabel') }}</label>
						<div class="row">
							<div class="col-lg-5">
								<MultiSelect v-model="selectedExclude" mode="single" object groupLabel="title" groupOptions="values"
									:close-on-select="false" :groups="true" :options="productServices" :loading="firstSelectorIsLoading"
									label-prop="title" value-prop="service_value_id" track-by="title" />
							</div>
							<div class="col-lg-5">
								<MultiSelect v-model="selectedExcludeFrom" mode="single" object groupLabel="title" groupOptions="values"
									:close-on-select="false" :groups="true" :options="excludeFromSelectorItems" label-prop="title"
									value-prop="service_value_id" track-by="title" />
							</div>
							<div class="col-lg-2 col-12 mx-auto">
								<button class="btn button-action m-0 w-100" @click="addToExcludedList">{{
									$t('product.addServices.excludeButtonLabel') }}</button>
							</div>
						</div>
					</div>
				</div>
				<div class="collapse show">
					<div class="card-body">
						<font-awesome-icon v-if="isLoading" :icon="['fas', 'circle-notch']" spin></font-awesome-icon>
						<ul v-else class="list-group">
							<li v-for="(item, index) in excludedServiceValues" :key="index" class="list-group-item">
								<div class="row py-2">
									<div class="col-md-5 d-flex align-items-center">
										{{ productServiceValues.find(el => el.service_value_id === item.exclude).title }}
									</div>
									<div class="col-md-5 d-flex align-items-center">
										{{ productServiceValues.find(el => el.service_value_id === item.from).title }}
									</div>
									<div class="col-md-2">
										<button class="btn" @click="excludedServiceValues.splice(index, 1)">
											<font-awesome-icon :icon="['fas', 'trash']"></font-awesome-icon>
										</button>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
