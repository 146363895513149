<script setup>
import { ref, reactive, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

// Import Components
import BodyHeading from '../../components/BodyHeading.vue'
import VInput from '../../components/Input.vue'
import TextEditor from '../../components/TextEditor.vue'
import Textarea from '../../components/Textarea.vue'
import CurrencyInput from '../../components/CurrencyInput.vue'
import FileUploader from '../../components/FileUploader.vue'
import MultiSelect from '../../components/MultiSelect.vue'
import draggable from 'vuedraggable'
import { useNotification } from "@kyvg/vue3-notification"

// Import vue-i18n
import { useI18n } from 'vue-i18n'

// Import validators
import useValidate from '@vuelidate/core'
import { required } from '@/utilities/i18n-validators.js'
import { requiredIf } from '@vuelidate/validators'

// Import TextEditor helpers
import useCkeditorHelper from '../../composables/useCkeditorHelper.js'

// setup validation
const product = reactive({ product_info: {} })
const defaultLanguageProduct = reactive({ product_info: {} })
const rules = {
  product: {
    title: { required },
    benefit_desc: { required },
    item_desc: { required },
    feature_desc: { required },
    // seo_title: { required },
    // seo_meta: { required },
    // seo_description: { required },
    product_info: {
      code: {
        required: requiredIf(() => {
          return !isInputDisable.value
        }),
      },
      price: {
        required: requiredIf(() => {
          return !isInputDisable.value
        }),
      },
      default_menu_id: {
        required: requiredIf(() => {
          return !isInputDisable.value
        }),
      }
    }
  },
}
const v$ = useValidate(rules, { product })

const { notify } = useNotification()
const { t } = useI18n()

const route = useRoute()
const router = useRouter()
const store = useStore()

// form state
const exists = ref(true)
const isLoading = ref(true)
const isInputDisable = ref(false)
const technical_info_id = ref([])
const portfolio_id = ref([])
const cover_image = ref([])
const video = ref([])
const data_sheet_pdf = ref([])
const assembly_pdf = ref([])
const template_design = ref([])
const productMenus = ref([])
const activeTabIndex = ref(0)

const { parseForEditor, parseForServer } = useCkeditorHelper()

// computed
const selectedLocale = computed(() => store.state.locale.selectedLocale)
const appLocale = computed(() => store.state.locale.appLocale)
const inTranslationMode = computed(() => selectedLocale.value !== appLocale.value)
const handleClickFunction = computed(() => {
  if (route.name.includes('Create'))
    return { handleClick: create }
  else {
    if (exists.value)
      return { handleClick: edit }
    else return { handleClick: createTranslation }
  }
})

// watch
watch(() => route.name, async (value) => {
  if (value.includes('Details')) {
    if (!defaultLanguageProduct.code) {
      await getProduct(route.params.id, appLocale.value)
      getProductMenus()
    }
    if (exists.value && inTranslationMode.value)
      await getProduct(route.params.id)
  } else {
    isLoading.value = false
  }
}, { immediate: true })

watch(selectedLocale, (value) => {
  if (route.name.includes('Details')) {
    isLoading.value = true
    activeTabIndex.value = 0
    v$.value.$reset();
    Object.assign(product, { product_info: {} })
    getProduct(route.params.id, value)
  }
})

// methods
async function getProduct(id, language = selectedLocale.value) {
  const urlParams = { language: language, product_id: id }
  return store.dispatch("product/fetch", urlParams).then(
    (response) => {
      const res = response
      res.feature_desc = parseForEditor(response.feature_desc)
      res.item_desc = parseForEditor(response.item_desc)
      res.benefit_desc = parseForEditor(response.benefit_desc)

      if (response.seo_meta) {
        var seometa = response.seo_meta;
        delete response.seo_meta;
        product.seo_title = seometa.title;
        // product.seo_meta = seometa.meta;
        product.seo_description = seometa.description;
      }

      if (response.product_info.cover_image && !cover_image.value.length) {
        cover_image.value.push({ id: null, name: response.product_info.cover_image })
      }

      var find = response.product_info.files.filter(file => file.type === 'video')
      console.log("🚀 ~ getProduct ~ find:", find)
      if (find.length > 0) {
        video.value = response.product_info.files.filter(file => file.type === 'video').map((file, index) => ({ id: file.id, name: file.path, arrange: file.arrange ?? index + 1 }))
      }
      if (response.product_info.data_sheet_pdf && !data_sheet_pdf.value.length) {
        data_sheet_pdf.value.push({ id: null, name: response.product_info.data_sheet_pdf })
      }
      if (response.product_info.assembly_pdf && !assembly_pdf.value.length) {
        assembly_pdf.value.push({ id: null, name: response.product_info.assembly_pdf })
      }
      if (response.product_info.zip && !template_design.value.length) {
        template_design.value.push({ id: null, name: response.product_info.zip })
      }
      exists.value = true
      Object.assign(defaultLanguageProduct, res)
      Object.assign(product, res)
      isLoading.value = false
      if (appLocale.value === language) {
        isInputDisable.value = false
      }
      else {
        isInputDisable.value = true
      }
    }).catch(() => {
      exists.value = false
      isLoading.value = false
      if (defaultLanguageProduct.title) {
        Object.assign(product, { product_info: {} })
        isInputDisable.value = true
      } else {
        notify({
          text: t('errors.notFoundInDefaultLocale', { entity: t('product.entityName') }),
          group: "failed"
        })
        router.push({ name: 'products', query: { page: 1 } })
      }
    })
}

function create() {
  v$.value.$validate()
  if (!v$.value.$error) {
    const newProduct = {
      price: product.product_info.price,
      title: product.title,
      language: appLocale.value
    }

    if (product.seo_title) {
      newProduct.seo_title = product.seo_title
    }

    // if (product.seo_meta) {
    //   newProduct.seo_meta = product.seo_meta
    // }

    if (product.seo_description) {
      newProduct.seo_description = product.seo_description
    }

    if (product.product_info.code) {
      newProduct.code = product.product_info.code
    }
    if (cover_image.value.length > 0) {
      newProduct.cover_image = cover_image.value[0].name
    }
    if (video.value.length > 0) {
      newProduct.video = video.value.map(file => ({ id: file.id, path: file.name, arrange: file.arrange }))
    } else {
      newProduct.video = []
    }
    if (data_sheet_pdf.value.length > 0) {
      newProduct.data_sheet_pdf = data_sheet_pdf.value[0].name
    }
    if (assembly_pdf.value.length > 0) {
      newProduct.assembly_pdf = assembly_pdf.value[0].name
    }
    if (template_design.value.length > 0) {
      newProduct.zip = template_design.value[0].name
    }
    if (product.feature_desc) {
      newProduct.feature_desc = parseForServer(product.feature_desc)
    }
    if (product.item_desc) {
      newProduct.item_desc = parseForServer(product.item_desc)
    }
    if (product.benefit_desc) {
      newProduct.benefit_desc = parseForServer(product.benefit_desc)
    }
    store.dispatch("product/create", newProduct).then(
      (data) => {
        if (technical_info_id.value.length > 0) {
          attachProductTechnicalInfo(technical_info_id.value, data.body.product_id)
        }
        if (portfolio_id.value.length > 0) {
          attachProductPortfolio(portfolio_id.value, data.body.product_id)
        }
        if (productMenus.value.length > 0) {
          attachProductMenus(data.body.product_id)
        }
        notify({
          text: data.message,
          group: 'success'
        })
        router.push({ name: 'productDetails', params: { id: data.body.product_id }, query: { name: data.body.title } })
      },
      (error) => {
        let errorMessage = ''
        for (const err in error) {
          errorMessage += `${error[err]} <br>`
        }
        notify({
          text: errorMessage,
          group: 'failed'
        })
      }
    )
  }
}

function edit() {
  v$.value.$validate()
  if (v$.value.$error) {
    return
  }
  const urlParams = {
    language: selectedLocale.value,
    product_id: defaultLanguageProduct.product_id
  }
  const editedProduct = {
    title: product.title,
    seo_title: product.seo_title,
    // seo_meta: product.seo_meta,
    seo_description: product.seo_description,
    price: product.product_info.price,
    reorder: product.product_info.reorder,
    cover_image: product.product_info.cover_image,
    video: product.product_info.video,
    data_sheet_pdf: product.product_info.data_sheet_pdf,
    assembly_pdf: product.product_info.assembly_pdf,
    zip: product.product_info.zip,
    benefit_desc: parseForServer(product.benefit_desc),
    item_desc: parseForServer(product.item_desc),
    feature_desc: parseForServer(product.feature_desc),
    code: product.product_info.code,
  }
  if (technical_info_id.value.length > 0) {
    attachProductTechnicalInfo(technical_info_id.value, product.product_id)
  } else {
    attachProductTechnicalInfo(null, product.product_id)
  }
  if (portfolio_id.value.length > 0) {
    attachProductPortfolio(portfolio_id.value, product.product_id)
  } else {
    attachProductPortfolio(null, product.product_id)
  }
  if (!product.feature_desc) {
    delete editedProduct.feature_desc
  }
  if (cover_image.value.length > 0) {
    editedProduct.cover_image = cover_image.value[0].name
  }
  if (video.value.length > 0) {
    editedProduct.video = video.value.map(file => ({ id: file.id, path: file.name, arrange: file.arrange }))
  } else {
    editedProduct.video = []
  }
  if (data_sheet_pdf.value.length > 0) {
    editedProduct.data_sheet_pdf = data_sheet_pdf.value[0].name
  }
  if (assembly_pdf.value.length > 0) {
    editedProduct.assembly_pdf = assembly_pdf.value[0].name
  }
  if (template_design.value.length > 0) {
    editedProduct.zip = template_design.value[0].name
  }
  if (productMenus.value.length > 0) {
    attachProductMenus(route.params.id)
  }
  store.dispatch("product/update", { urlParams, editedProduct }).then(
    (message) => {
      notify({
        text: message,
        group: 'success'
      })
    },
    (error) => {
      let errorMessage = ''
      for (const err in error) {
        errorMessage += `${error[err]} <br>`
      }
      notify({
        text: errorMessage,
        group: 'failed'
      })
    }
  )
}

function createTranslation() {
  v$.value.$validate()
  if (!v$.value.$error) {
    const urlParams = {
      product_id: defaultLanguageProduct.product_id
    }
    const translatedProduct = { title: product.title }

    if (product.feature_desc) {
      translatedProduct.feature_desc = parseForServer(product.feature_desc)
    }
    if (product.item_desc) {
      translatedProduct.item_desc = parseForServer(product.item_desc)
    }
    if (product.benefit_desc) {
      translatedProduct.benefit_desc = parseForServer(product.benefit_desc)
    }
    translatedProduct.language = selectedLocale.value
    store.dispatch("product/createTranslation", { urlParams, product: translatedProduct }).then(
      (message) => {
        exists.value = true
        notify({
          text: message,
          group: 'success'
        })
        getProduct(product.product_id, selectedLocale.value)
      },
      (error) => {
        let errorMessage = ''
        for (const err in error) {
          errorMessage += `${error[err]} <br>`
        }
        notify({
          text: errorMessage,
          group: 'failed'
        })
      }
    )
  }
}

function getTechnicalInfos(query) {
  const params = { language: appLocale.value, per_page: 30 }
  if (query) {
    params.title = query
  }
  return store.dispatch("technicalInfo/getAll", params).then(
    (response) => {
      let technicalInfos = []
      response.data.forEach(el => {
        technicalInfos.push({ value: el.technical_info_id, label: el.title })
      })
      return technicalInfos
    },
    (error) => {
      notify({
        text: error,
        group: 'failed'
      })
    }
  )
}

function getPortfolios(query) {
  const params = { language: appLocale.value, per_page: 30 }
  if (query) {
    params.title = query
  }
  return store.dispatch("portfolio/getAll", params).then(
    (response) => {
      let portfolios = []
      response.data.forEach(el => {
        portfolios.push({ value: el.id, label: el.title })
      })
      return portfolios
    },
    (error) => {
      notify({
        text: error,
        group: 'failed'
      })
    }
  )
}

function getProductTechnicalInfo(id) {
  const urlParams = { product_id: id }
  const queryParams = { per_page: 30 }
  store.dispatch("product/technicalInfo/getTechnicalInfo", { urlParams, queryParams }).then(
    (response) => {
      if (response) {
        response.sort((a, b) => (a.pivot.arrange > b.pivot.arrange) ? 1 : -1)
        response.forEach(el => {
          technical_info_id.value.push({ value: el.technical_info_id, label: el.title })
        })
      }
    },
    (error) => {
      notify({
        text: error,
        group: 'failed'
      })
    }
  )
}

function getProductPortfolio(id) {
  const urlParams = { product_id: id }
  const queryParams = { per_page: 30 }
  store.dispatch("product/portfolio/getPortfolio", { urlParams, queryParams }).then(
    (response) => {
      if (response) {
        portfolio_id.value.push({ value: response.id, label: response.title })
      }
    },
    (error) => {
      notify({
        text: error,
        group: 'failed'
      })
    }
  )
}

function attachProductTechnicalInfo(productTechnicalInfo, id) {
  let productTemp
  if (productTechnicalInfo == null || productTechnicalInfo == []) {
    productTemp = { technical_infos: [], product_id: id }
  } else {
    productTemp = { technical_infos: [], product_id: id }
    productTechnicalInfo.forEach((el, index) => {
      productTemp.technical_infos.push({ id: el.value, arrange: index + 1 })
    })
  }

  store.dispatch("product/technicalInfo/attachTechnicalInfo", productTemp).then(
    () => {
      // notify({
      //       text: message.message[0],
      //       group: 'success'
      // })
    },
    (error) => {
      let errorMessage = ''
      for (const err in error) {
        errorMessage += `${error[err]} <br>`
      }
      notify({
        text: errorMessage,
        group: 'failed'
      })
    }
  )
}

function attachProductPortfolio(productPortfolio, id) {
  let productTemp
  if (productPortfolio == null) {
    productTemp = { portfolio_id: null, product_id: id }
  } else {
    productTemp = { portfolio_id: productPortfolio[0].value, product_id: id }
  }
  store.dispatch("product/portfolio/attachPortfolio", productTemp).then(
    () => {
      // notify({
      //       text: message.message[0],
      //       group: 'success'
      // })
    },
    (error) => {
      let errorMessage = ''
      for (const err in error) {
        errorMessage += `${error[err]} <br>`
      }
      notify({
        text: errorMessage,
        group: 'failed'
      })
    }
  )
}

function getMenus(query) {
  const queryParams = { language: selectedLocale.value, title: query, per_page: 50, level: 2 }
  return store.dispatch('menu/getAll', queryParams).then(res => {
    return res.data.map(el => ({ label: el.title, value: el.menu_id }))
  }).catch(error => {
    notify({
      text: error,
      group: 'failed'
    })
  })
}

function getProductMenus() {
  const urlParams = { product_id: route.params.id }
  return store.dispatch('product/menus/getMenus', urlParams).then(res => {
    productMenus.value = res.map(el => ({ label: el.menu_t.title, value: el.pivot.menu_id }))
  }).catch(error => {
    notify({
      text: error,
      group: 'failed'
    })
  })
}

function attachProductMenus(product_id) {
  const urlParams = { product_id }
  const menus = {
    menus: productMenus.value.map(el => el.value),
    default: product.product_info.default_menu_id
  }
  store.dispatch('product/menus/setMenus', { urlParams, menus })
    .catch(error => {
      notify({
        text: error,
        group: 'failed'
      })
    })
}

if (route.name.includes('Details')) {
  getProductTechnicalInfo(route.params.id)
  getProductPortfolio(route.params.id)
}
</script>

<template>
  <BodyHeading :borderColor="'rgb(87, 217, 163)'"
    :title="$route.name.includes('Create') ? $t('product.addButtonTitle') : $t('product.editTitle')"
    :multiLanguageDisabled="$route.name.includes('Create')"
    :saveAndCloseBtn="!$route.name.includes('Create')" v-on="handleClickFunction" />

  <div class="page-body-main-create">
    <div class="card-wrapper">
      <nav class="nav nav-stepper">
        <a class="nav-link" :class="{active: activeTabIndex === 0}" @click="activeTabIndex = 0">{{$t('generalInformation')}}</a>
        <a class="nav-link" :class="{active: activeTabIndex === 1, 'disabled': inTranslationMode}" @click="activeTabIndex = 1">{{$t('media')}}</a>
        <a class="nav-link" :class="{active: activeTabIndex === 2, 'disabled': inTranslationMode}" @click="activeTabIndex = 2">SEO</a>
      </nav>
      <div v-if="activeTabIndex === 0" class="card">
        <div class="card-body">
          <!--  -->
          <div v-if="!isLoading" class="row">
            <div class="mb-4">
              <v-input type="text" name="title" label="common.title" :placeholder="defaultLanguageProduct.title"
                v-model="product.title" :isRequired="true"
                :validateError="v$.product.title.$error ? v$.product.title.$errors[0].$message : null"></v-input>
            </div>
            <div v-if="!isInputDisable" class="mb-4">
              <currency-input label="common.price" name="custom-price" :isRequired="true" :disabled="isInputDisable"
                v-model="product.product_info.price"
                :validateError="v$.product.product_info.price.$error ? v$.product.product_info.price.$errors[0].$message : null" />
            </div>
            <div v-if="!isInputDisable" class="mb-4">
              <v-input type="text" name="code" label="product.create.code"
                :placeholder="defaultLanguageProduct.product_info.code" v-model="product.product_info.code"
                :isRequired="true"
                :validateError="v$.product.product_info.code.$error ? v$.product.product_info.code.$errors[0].$message : null"></v-input>
            </div>
            <div v-if="!isInputDisable" class="mb-4">
              <MultiSelect v-model="productMenus" label="product.create.menu" mode="tags" :max="5"
                :options="async (query) => { return await getMenus(query) }" :delay="0" :resolveOnLoad="true"
                :searchable="true" :object="true" />
              <div class="mt-2">
                <span class="me-2" :class="{ 'invalid-feedback': v$.product.product_info.default_menu_id.$error }">
                  {{ t('product.create.defaultMenu') }}:
                </span>
                <span v-for="menu in productMenus" :key="menu.value">
                  <input v-model="product.product_info.default_menu_id" :value="menu.value" type="radio" class="btn-check"
                    name="options" :id="'option' + menu.value" autocomplete="off">
                  <label class="btn mb-1 me-2" :for="'option' + menu.value">{{ menu.label }}</label>
                </span>
              </div>
            </div>
            <div class="mb-4">
              <TextEditor name="benefitDescription" label="product.create.benefit-desc-label"
                v-model="product.benefit_desc" :isRequired="true"
                :validateError="v$.product.benefit_desc.$error ? v$.product.benefit_desc.$errors[0].$message : null">
              </TextEditor>
            </div>
            <div class="mb-4">
              <TextEditor name="itemDescription" label="product.create.item-desc-label" v-model="product.item_desc"
                :isRequired="true"
                :validateError="v$.product.item_desc.$error ? v$.product.item_desc.$errors[0].$message : null">
              </TextEditor>
            </div>
            <div class="mb-4">
              <TextEditor name="featureDescription" label="product.create.feature-desc-label"
                v-model="product.feature_desc" :isRequired="true"
                :validateError="v$.product.feature_desc.$error ? v$.product.feature_desc.$errors[0].$message : null">
              </TextEditor>
            </div>
            <div v-if="!isInputDisable" class="mb-4">
              <MultiSelect label="product.create.technicalInfoLabel" mode="tags"
                :options="async (query) => { return await getTechnicalInfos(query) }" :modelValue="technical_info_id"
                @update:modelValue="technical_info_id = $event" :delay="0" :resolveOnLoad="true" :searchable="true"
                :object="true" />
            </div>
            <div v-if="!isInputDisable && technical_info_id.length" class="mb-4">
              <span>{{ `${$t('product.create.sortSelectedTechnicalInfos')}` }}</span>
              <draggable v-model="technical_info_id" tag="ul" item-key="value" class="list-group my-2"
                handle=".drag-handler">
                <template #item="{ element }">
                  <li class="list-group-item">
                    <span class="drag-handler me-3 mp-1 d-inline-block cursor-move">
                      <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                      <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                    </span>
                    {{ `${element.label}` }}
                  </li>
                </template>
              </draggable>
            </div>
            <div v-if="!isInputDisable" class="mb-4">
              <MultiSelect label="product.create.choosePortfolioLabel" mode="tags" :max="1"
                :options="async (query) => { return await getPortfolios(query) }" :modelValue="portfolio_id"
                @update:modelValue="portfolio_id = $event" :delay="0" :resolveOnLoad="true" :searchable="true"
                :object="true" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeTabIndex === 1" class="card">
        <div class="card-body">
          <div v-if="!isLoading" class="row">
            <div class="mb-4">
              <FileUploader v-model="cover_image" :name="'product-cover-uploader'" label="product.create.chooseCoverLabel"
                :multiple="false" :ownerId="{ product_id: $route.name.includes('Details') ? $route.params.id : null }"
                uploadUrl="products/files/upload/cover-image" deleteUrl="products/files/delete/cover-image"
                fetchUrl="image/product" />
            </div>
            <div class="mb-4">
              <FileUploader v-model="video" :name="'product-video-uploader'" label="product.create.chooseVideoLabel"
                :multiple="true" :ownerId="{ product_id: $route.name.includes('Details') ? $route.params.id : null }"
                uploadUrl="products/files/upload/video" deleteUrl="products/files/delete/video" fetchUrl="video/product"
                type="video" />
            </div>
            <div class="mb-4">
              <FileUploader v-model="data_sheet_pdf" :name="'product-data-uploader'"
                label="product.create.chooseDatasheetLabel" :multiple="false"
                :ownerId="{ product_id: $route.name.includes('Details') ? $route.params.id : null }"
                uploadUrl="products/files/upload/data-sheet" deleteUrl="products/files/delete/data-sheet"
                fetchUrl="file/product/datasheet" type="file" />
            </div>
            <div class="mb-4">
              <FileUploader v-model="assembly_pdf" :name="'product-assembly-uploader'"
                label="product.create.chooseAssemblyLabel" :multiple="false"
                :ownerId="{ product_id: $route.name.includes('Details') ? $route.params.id : null }"
                uploadUrl="products/files/upload/assembly" deleteUrl="products/files/delete/assembly"
                fetchUrl="file/product/assembly" type="file" />
            </div>
            <div class="mb-4">
              <FileUploader v-model="template_design" :name="'product-zip-uploader'"
                label="product.create.chooseTemplateDesignLabel" :multiple="false"
                :ownerId="{ product_id: $route.name.includes('Details') ? $route.params.id : null }"
                uploadUrl="products/files/upload/zip" deleteUrl="products/files/delete/zip" fetchUrl="file/product/zip"
                type="zip" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeTabIndex === 2" class="card">
        <div class="card-body">
          <div v-if="!isLoading" class="row">
            <div class="mb-4">
              <v-input type="text" name="title" label="Meta Titel"
                v-model="product.seo_title" :isRequired="true"></v-input>
            </div>
            
            <div class="mb-4">
              <Textarea name="benefitDescription" rows="6" label="Meta Description"
                v-model="product.seo_description" :isRequired="true">
              </Textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
