<template>
  <BodyHeading
    :borderColor="'rgb(160, 146, 240)'"
    :title="$t('order.ordersTitle')"
    :count="orders.total"
    :multiLanguageDisabled="true"
    :listHeading="true"
    :btnTitle="'. . .'"
    :createDisabled="true"
  ></BodyHeading>
  <div class="page-body-main-list d-grid grid-columns">
    <div class="table-responsive">
      <table class="table table-hover table-bordered order-list">
        <thead>
          <tr>
            <th scope="col">
              <span>{{ $t("order.orderNumber") }}</span>
            </th>
            <!-- <th scope="col"> -->
              <!-- <span>{{ $t("order.uniqueKey") }}</span> -->
            <!-- </th> -->
            <th scope="col" style="width: 10%">
              <span>{{ $t("order.totalPrice") }}</span>
            </th>
            <th scope="col" style="width: 5%">
              <span>{{ $t("order.country") }}</span>
            </th>
            <th scope="col">
              <span>{{ $t("order.customer") }}</span>
            </th>
            <th scope="col">
              <span>{{ $t("order.phoneNumber") }}</span>
            </th>
            <th scope="col">
              <span>{{ $t("order.email") }}</span>
            </th>
            <th scope="col" style="min-width: 130px">
              <span>{{ $t("order.purchaseDate") }}</span>
            </th>
            <th scope="col" style="width: 5%">
              <span>{{ $t("actions") }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in orders.data" :key="order.id" :class="{ 'new-order': order.seen == 0, 'canceled': order.state === 'canceled', 'ready': order.state === 'ready', 'completed': order.is_complete == 1 }">
            <td>SB{{ order?.invoice_id*4 }}</td>
            <!-- <td>{{ order?.payment_intent }}</td> -->
            <td>{{ order?.amount_total }}</td>
            <td v-if="order?.billing_country">
              {{ order?.billing_country.name }}
            </td>
            <td>{{ order?.user?.first_name + " " + order?.user?.last_name }}</td>
            <td>{{ order?.user?.phone }}</td>
            <td>{{ order?.user?.email }}</td>
            <td>{{ dateTime(order.created_at) }}</td>
            <td :style="order.payment_intent === ParentZindex ? { zIndex: 2 } : null">
              <div class="dropdown actions-dropdown">
                <button
                  @click="changeParentZindex(order.payment_intent)"
                  class="btn btn-light"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a
                      @click="
                        $router.push({
                          name: 'orderDetails',
                          params: { id: order.payment_intent },
                        })
                      "
                      class="dropdown-item"
                    >
                      {{ $t("details") }}
                    </a>
                  </li>
                  <li>
                    <a href="#" @click="exportCSV(order.payment_intent)" class="dropdown-item">
                      <!-- <download-csv :data="json_data" @export-requested=""></download-csv> -->
                    CSV herunterladen
                    </a>
                  </li>
                  <!-- <li><a class="dropdown-item warning" @click="deleteItem(order.id)">Delete</a></li> -->
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Filter Box -->
    <div class="filter-bar">
      <aside :class="{ opened: filterOpened }">
        <nav>
          <ul>
            <li>
              <button :class="{ active: filterOpened }" @click="filterOpened = !filterOpened">
                <font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon>
              </button>
            </li>
          </ul>
        </nav>
        <div class="filter-content">
          <div class="filter-panel">
            <div class="headline">
              <h3>{{ $t("filters.title") }}</h3>
              <button @click="filterOpened = !filterOpened">
                <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
              </button>
            </div>
            <div class="content">
              <div>
                <div>
                  <div class="element">
                    <v-input type="text" name="title" label="order.orderNumber" v-model="orderNumber" @keyup="searchQueue()"></v-input>
                  </div>
                </div>
                <div>
                  <div class="element">
                    <v-input type="text" name="title" label="order.customerFirstName" v-model="first_name" @keyup="searchQueue()"></v-input>
                  </div>
                </div>
                <div>
                  <div class="element">
                    <v-input type="text" name="title" label="order.customerLastName" v-model="last_name" @keyup="searchQueue()"></v-input>
                  </div>
                </div>
                <div>
                  <div class="element">
                    <v-input type="text" name="title" label="order.email" v-model="email" @keyup="searchQueue()"></v-input>
                  </div>
                </div>
                <div>
                  <div class="element">
                    <v-input type="text" name="title" label="order.uniqueKey" v-model="payment_intent" @keyup="searchQueue()"></v-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
    <!-- // Filter Box -->

    <Pagination
      v-if="orders.total > orders.per_page"
      :lastPage="orders.last_page"
      :activePage="orders.current_page"
      @handleClick="changePage"
    ></Pagination>
  </div>
</template>

<script>
import BodyHeading from "../../components/BodyHeading.vue";
import Pagination from "../../components/Pagination.vue";
import Input from "../../components/Input.vue";
import { ITEMS_PER_PAGE } from "../../constants";
import exportFromJSON from "export-from-json";
export default {
  inject: ['dayjs'],
  components: {
    BodyHeading,
    Pagination,
    "v-input": Input,
  },
  data() {
    return {
      orderNumber: null,
      first_name: null,
      last_name: null,
      payment_intent: null,
      json_data: null,
      email: null,
      timer: null,
      filterOpened: false,
      orders: {},
      ParentZindex: null,
    };
  },
  created() {
    if (Object.entries(this.$route.query).length === 0 || this.$route.query.page === "") {
      this.$router.push({ name: "orders", query: { page: 1 } });
    }
    this.getInvoices()
    const { first_name, last_name, payment_intent, email } = this.$route.query
    this.first_name = first_name? first_name : null
    this.last_name = last_name? last_name : null
    this.payment_intent = payment_intent? payment_intent : null
    this.email = email? email : null
    if( first_name || last_name || payment_intent || email ){
        this.filterOpened = true
    }
  },
  watch: {
    '$route.query'(){
      this.getInvoices()
    },
    selectedLocale() {
      this.getInvoices()
    },
  },
  computed: {
    selectedLocale() {
      return this.$store.state.locale.selectedLocale
    },
  },
  methods: {
    formatDateToGerman(dateString) {
      const date = new Date(dateString);
      const pad = (num) => (num < 10 ? '0' : '') + num;
      const day = pad(date.getDate());
      const month = pad(date.getMonth() + 1);
      const year = date.getFullYear();
      const hours = pad(date.getHours());
      const minutes = pad(date.getMinutes());
      const seconds = pad(date.getSeconds());
      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    },
    exportCSV(id){
      const params = { language: this.selectedLocale, per_page: ITEMS_PER_PAGE, ...this.$route.query, id: id };
      this.$store.dispatch("order/get_csv", params).then(
        (response) => {
          var list = [];
          var invoice   = response.invoice;
          var address   = response.address;
          var user   = response.user;
          var products  = response.products;

          var item = {
            OrderNumber: '',
            // ExternalOrderNumber: '',
            CustomerNumber: '',
            OrderDate: '',
            InvoiceSalutation: '',
            InvoiceTitle: '',	
            InvoiceFirstName: '',
            InvoiceLastname: '',
            InvoiceCompany: '',
            InvoiceCompanyAddition: '',
            InvoiceStreet: '',
            InvoiceStreetAddition: '',
            InvoiceZipCode: '',
            InvoiceCity: '',
            InvoiceCountry: '',
            InvoiceEMail: '',
            InvoicePhoneNumber: '',	
            InvoiceVatNumber: '',
            DeliveryCompany: '',
            DeliveryCompanyAddition: '',
            DeliverySalutation: '',
            DeliveryTitle: '',	
            DeliveryFirstName: '',
            DeliveryLastName: '',
            DeliveryStreet: '',
            DeliveryStreetAddition: '',
            DeliveryZipCode: '',
            DeliveryCity: '',
            DeliveryCountry: '',
            DeliveryEMail: '',	
            DeliveryPhoneNumber: '',
            ArticleNumber: '',
            ShippingMethodeName: '',
            ArticleName: '',
            ArticlePriceNetto: '',
            ArticlePriceVatInPercent: '',
            ArticlePriceDiscount: '',
            ArticleQuantity: '',
            PositionType: 'Artikel',	
            PaymentMethodName: '',
            PaymentAmount: '',
            PaymentDate: '',
          };

          if(address.length <= 1) {
              let invoiceName;
              if(invoice.payment_type === "prePaid") {
                invoiceName = 'Vorkasse (2% Rabatt)';
              } else if(invoice.payment_type === "postPaid") {
                invoiceName = 'Rechnungskauf';
              } else {
                invoiceName = invoice.payment_type;
              }

              for (let i = 0; i < products.length; i++) {
                item.OrderNumber = 'SB'+(invoice.invoice_id*4);
                // item.ExternalOrderNumber = invoice.invoice_id ?? '';
                item.CustomerNumber = 'SK'+user.id ?? '';
                item.OrderDate = this.formatDateToGerman(address[0].created_at);
                item.InvoiceSalutation = (user?.gender === "male" ? 'Herr' : 'Frau');
                item.InvoiceTitle = '';
                item.InvoiceFirstName = user.first_name ?? '';
                item.InvoiceLastname = user.last_name ?? '';
                item.InvoiceCompany = user.company ?? '';
                item.InvoiceCompanyAddition = '';
                item.InvoiceStreet = invoice.address ?? '';
                item.InvoiceStreetAddition = invoice.additional_address ?? '';
                item.InvoiceZipCode = invoice.postcode ?? '';
                item.InvoiceCity = invoice.city ?? '';
                item.InvoiceCountry = invoice.country_name ?? '';
                item.InvoiceEMail = user.email ?? '';
                item.InvoicePhoneNumber = user.phone ?? '';
                item.InvoiceVatNumber = invoice.ust_id ?? '';
                item.DeliveryCompany = address[0].address.company ?? '';
                item.DeliveryCompanyAddition = '';
                item.DeliverySalutation = (address[0].address.gender === "male" ? 'Herr' : 'Frau');
                item.DeliveryFirstName = address[0].address.first_name ?? '';
                item.DeliveryLastName = address[0].address.last_name ?? '';
                item.DeliveryStreet = address[0].address.address ?? '';
                item.DeliveryStreetAddition = address[0].address.additional_address ?? '';
                item.DeliveryZipCode = address[0].address.postcode ?? '';
                item.DeliveryCity = address[0].address.city ?? '';
                item.DeliveryCountry = address[0].country_name ?? '';
                item.DeliveryEMail = address[0].address.email ?? '';
                item.DeliveryPhoneNumber = address[0].address.phone ?? '';
                item.DeliveryTitle = '';
                item.ShippingMethodeName = address[0].post?.title ?? '';
                item.ArticleNumber = products[i].product.code ?? '';	
                item.PaymentMethodName = invoiceName ?? '';

                var totalPrice = 0;
                var totalDiscount = 0;
                var totalDiscountArticle = 0;
                var percentDiscount = (address[0].country_id !== 10) ? 19: 0;
                
                if(!isNaN(parseFloat(products[i].total_price))) totalPrice += parseFloat(products[i].total_price);
                if(!isNaN(parseFloat(products[i].services_total_price))) totalPrice += parseFloat(products[i].services_total_price);
                
                if(invoice.coupon_percent !== 0) {
                      totalDiscountArticle += invoice.coupon_percent;
                }

                if(percentDiscount !== 0) {
                    totalDiscount += totalPrice*(percentDiscount/100);
                }
                
                if(!isNaN(parseFloat(products[i].pre_paid_coupon_price))) {
                  totalPrice += parseFloat(products[i].pre_paid_coupon_price);
                }

                if(invoice.payment_type === "prePaid") {
                  totalDiscountArticle += products[i].pre_paid_percent;
                }

                item.ArticleName = products[i].product_title ?? '';
                item.ArticlePriceNetto = totalPrice.toFixed(2);	
                item.ArticlePriceVatInPercent = percentDiscount.toFixed(2);	
                item.ArticlePriceDiscount = totalDiscountArticle ?? '';
                item.ArticleQuantity = products[i].quantity ?? '';
                item.DiscountedArticlePrice = products[i].total_price ?? '';
                item.PositionType = 'Artikel';	

                item.PaymentAmount = invoice.amount_total ?? '';

                var dates = new Date(products[i].created_at).toLocaleString('de-DE');
                item.PaymentDate = dates.split(',').join('');
                item.Comment = (invoice.comments !== null) ? invoice.comments : '';

                list.push(item);

                item = {
                  OrderNumber: '',
                  // ExternalOrderNumber: '',
                  CustomerNumber: '',
                  OrderDate: '',
                  InvoiceSalutation: '',
                  InvoiceTitle: '',	
                  InvoiceFirstName: '',
                  InvoiceLastname: '',
                  InvoiceCompany: '',
                  InvoiceCompanyAddition: '',
                  InvoiceStreet: '',
                  InvoiceStreetAddition: '',
                  InvoiceZipCode: '',
                  InvoiceCity: '',
                  InvoiceCountry: '',
                  InvoiceEMail: '',
                  InvoicePhoneNumber: '',	
                  InvoiceVatNumber: '',
                  DeliveryCompany: '',
                  DeliveryCompanyAddition: '',
                  DeliverySalutation: '',
                  DeliveryTitle: '',	
                  DeliveryFirstName: '',
                  DeliveryLastName: '',
                  DeliveryStreet: '',
                  DeliveryStreetAddition: '',
                  DeliveryZipCode: '',
                  DeliveryCity: '',
                  DeliveryCountry: '',
                  DeliveryEMail: '',	
                  DeliveryPhoneNumber: '',
                  ArticleNumber: '',
                  ShippingMethodeName: '',
                  ArticleName: '',
                  ArticlePriceNetto: '',
                  ArticlePriceVatInPercent: '',
                  ArticlePriceDiscount: '',
                  ArticleQuantity: '',
                  PositionType: 'Artikel',	
                  PaymentMethodName: '',
                  PaymentAmount: '',
                  PaymentDate: '',
                };

              }
              const data = list;
              const fileName =  invoice.invoice_id+'_'+invoice.created_at;
              const exportType = exportFromJSON.types.csv;
              setTimeout(() => {
                if(data) exportFromJSON({ data, fileName, exportType });
              },3000)
          } else {

              let invoiceName;
              if(invoice.payment_type === "prePaid") {
                invoiceName = 'Vorkasse (2% Rabatt)';
              } else if(invoice.payment_type === "postPaid") {
                invoiceName = 'Rechnungskauf';
              } else {
                invoiceName = invoice.payment_type;
              }

              for (let i = 0; i < address.length; i++) {
                item.OrderNumber = 'SB'+(invoice.invoice_id*4);
                // item.ExternalOrderNumber = invoice.invoice_id ?? '';
                item.CustomerNumber = 'SK'+user.id ?? '';
                item.OrderDate = this.formatDateToGerman(address[i].created_at);
                item.InvoiceSalutation = (user?.gender === "male" ? 'Herr' : 'Frau');
                item.InvoiceTitle = '';
                item.InvoiceFirstName = user.first_name ?? '';
                item.InvoiceLastname = user.last_name ?? '';
                item.InvoiceCompany = user.company ?? '';
                item.InvoiceCompanyAddition = '';
                item.InvoiceStreet = invoice.address ?? '';
                item.InvoiceStreetAddition = invoice.additional_address ?? '';
                item.InvoiceZipCode = invoice.postcode ?? '';
                item.InvoiceCity = invoice.city ?? '';
                item.InvoiceCountry = invoice.country_name ?? '';
                item.InvoiceEMail = user.email ?? '';
                item.InvoicePhoneNumber = user.phone ?? '';
                item.InvoiceVatNumber = invoice.ust_id ?? '';
                item.DeliveryCompany = address[i].address.company ?? '';
                item.DeliveryCompanyAddition = '';
                item.DeliverySalutation = (address[i].address.gender === "male" ? 'Herr' : 'Frau');
                item.DeliveryFirstName = address[i].address.first_name ?? '';
                item.DeliveryLastName = address[i].address.last_name ?? '';
                item.DeliveryStreet = address[i].address.address ?? '';
                item.DeliveryStreetAddition = address[i].address.additional_address ?? '';
                item.DeliveryZipCode = address[i].address.postcode ?? '';
                item.DeliveryCity = address[i].address.city ?? '';
                item.DeliveryCountry = address[i].country_name ?? '';
                item.DeliveryEMail = address[i].address.email ?? '';
                item.DeliveryPhoneNumber = address[i].address.phone ?? '';
                item.DeliveryTitle = '';
                item.ArticleNumber = '';
                item.ShippingMethodeName = address[i].post?.title ?? '';
                item.ArticleName = '';
                item.ArticlePriceNetto = '';
                item.ArticlePriceVatInPercent = '';
                item.ArticlePriceDiscount = (address[i].country_id !== 10) ? 19: 0;
                item.ArticleQuantity = '';
                item.PositionType = 'Artikel';
                item.PaymentMethodName = invoiceName ?? '';
                item.PaymentAmount = '';
                item.PaymentDate = '';

                for (let j = 0; j < products.length; j++) {
                  if(address[i].order_address_id === products[j].order_address_id) {

                    totalPrice = 0;
                    totalDiscount = 0;
                    totalDiscountArticle = 0;
                    percentDiscount = (address[i].country_id !== 10) ? 19: 0;
                    
                    if(!isNaN(parseFloat(products[j].total_price))) totalPrice += parseFloat(products[j].total_price);
                    if(!isNaN(parseFloat(products[j].services_total_price))) totalPrice += parseFloat(products[j].services_total_price);
                    
                    if(invoice.coupon_percent !== 0) {
                        totalDiscountArticle += invoice.coupon_percent;
                    }

                    if(percentDiscount !== 0) {
                        totalDiscount += totalPrice*(percentDiscount/100);
                    }
                    
                    if(!isNaN(parseFloat(products[j].pre_paid_coupon_price))) {
                      totalPrice += parseFloat(products[j].pre_paid_coupon_price);
                    }

                    if(invoice.payment_type === "prePaid") {
                      totalDiscountArticle += products[j].pre_paid_percent;
                    }

                    item.ArticleNumber = products[j].product.code ?? '';
                    item.ArticleName = products[j].product_title ?? '';
                    item.ArticlePriceNetto = totalPrice.toFixed(2) ?? '';
                    item.ArticlePriceVatInPercent = percentDiscount ?? '';
                    item.ArticlePriceDiscount = totalDiscountArticle ?? '';
                    item.ArticleQuantity = products[j].quantity ?? '';
                    item.DiscountedArticlePrice = products[j].total_price ?? '';
                    item.PositionType = 'Artikel';	
                    item.PaymentAmount = parseFloat(totalPrice)+totalDiscount ?? '';
                    var datep = new Date(products[j].created_at).toLocaleString('de-DE');
                    item.PaymentDate = datep.split(',').join('');
                  }
                }

                item.Comment = (invoice.comments !== null) ? invoice.comments : '';

                list.push(item);
                item = {
                  OrderNumber: '',
                  // ExternalOrderNumber: '',
                  CustomerNumber: '',
                  OrderDate: '',
                  InvoiceSalutation: '',
                  InvoiceTitle: '',	
                  InvoiceFirstName: '',
                  InvoiceLastname: '',
                  InvoiceCompany: '',
                  InvoiceCompanyAddition: '',
                  InvoiceStreet: '',
                  InvoiceStreetAddition: '',
                  InvoiceZipCode: '',
                  InvoiceCity: '',
                  InvoiceCountry: '',
                  InvoiceEMail: '',
                  InvoicePhoneNumber: '',	
                  InvoiceVatNumber: '',
                  DeliveryCompany: '',
                  DeliveryCompanyAddition: '',
                  DeliverySalutation: '',
                  DeliveryTitle: '',	
                  DeliveryFirstName: '',
                  DeliveryLastName: '',
                  DeliveryStreet: '',
                  DeliveryStreetAddition: '',
                  DeliveryZipCode: '',
                  DeliveryCity: '',
                  DeliveryCountry: '',
                  DeliveryEMail: '',	
                  DeliveryPhoneNumber: '',
                  ArticleNumber: '',
                  ShippingMethodeName: '',
                  ArticleName: '',
                  ArticlePriceNetto: '',
                  ArticlePriceVatInPercent: '',
                  ArticlePriceDiscount: '',
                  ArticleQuantity: '',
                  PositionType: 'Artikel',	
                  PaymentMethodName: '',
                  PaymentAmount: '',
                  PaymentDate: '',
                };
              }

              const data = list;
              const fileName =  invoice.invoice_id+'_'+invoice.created_at;
              const exportType = exportFromJSON.types.csv;
              setTimeout(() => {
                if(data) exportFromJSON({ data, fileName, exportType });
              },3000)
          }
        }
      );
    },  
    searchQueue() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        const query = {
          page: 1,
          ...(this.orderNumber && {invoice_id: this.orderNumber}),
          ...(this.first_name && {first_name: this.first_name}),
          ...(this.last_name && {last_name: this.last_name}),
          ...(this.payment_intent && {payment_intent: this.payment_intent}),
          ...(this.email && {email: this.email}),
        }
        this.$router.push({
          query
        })
      }, 500);
    },
    dateTime(value) {
      return this.dayjs(value).format("YYYY-MM-DD");
    },
    changeParentZindex(id) {
      this.ParentZindex = id;
    },
    getInvoices() {
      const params = { language: this.selectedLocale, per_page: ITEMS_PER_PAGE, ...this.$route.query };
      this.$store.dispatch("order/getAll", params).then(
        (response) => {
          this.orders = response;
        },
        (error) => {
          this.$notify({
            text: error,
            group: "failed",
          });
        }
      );
    },
    changePage(page) {
      this.$router.push({ name: "orders", query: {...this.$route.query, page: page } });
    },
  },
};
</script>
